import React from 'react';
import styled from '@emotion/styled';

export function Headline({ children }) {
  return (
    <StyledHeadline className="text-5xl font-bold">{children}</StyledHeadline>
  );
}

const StyledHeadline = styled('h1')`
  color: ${(props: any) => props.theme.text};
`;
