import React from 'react';
import styled from '@emotion/styled';

export function Paragraph({ children }) {
  return (
    <StyledParagraph className="text-lg font-medium">
      {children}
    </StyledParagraph>
  );
}

const StyledParagraph = styled('p')`
  color: ${(props: any) => props.theme.text};
`;
