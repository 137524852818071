import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Image from '../components/image';
import { Row, Col, Container } from 'react-grid-system';
import Paragraph from '../components/Paragraph';
import Headline from '../components/Headline';

const IndexPage = () => (
  <Layout>
    <Wrapper>
      <Container className="container">
        <Row className="flex">
          <Col sm={12} md={6} className="sm:mb-12 md:mb-12 sm:mt-12 md:mt-12">
            <Image />
          </Col>
          <Col
            sm={12}
            md={6}
            className="self-center text-start sm:text-center md:text-center"
          >
            <Headline>
              Hi, I'm Felix{' '}
              <span role="img" aria-label="waving emoji">
                👋
              </span>
            </Headline>
            <Paragraph>I am a Software Engineer based in Berlin</Paragraph>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  </Layout>
);

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -80px;
`;

export default IndexPage;
